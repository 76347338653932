import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import React, { FormEvent } from "react";
import TextareaAutosize from "react-autosize-textarea";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface Props {
  pageName: string;
}

enum FormState {
  Normal = "normal",
  Success = "success",
  Submitting = "Submitting",
  Error = "error"
}

interface State {
  state: FormState;
  [name: string]: any;
}

function encode(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function fbTrack(action: string, title: string, data?: any) {
  if (
    process.env.NODE_ENV === `production` &&
    (window as any).fbq &&
    typeof (window as any).fbq === `function`
  ) {
    (window as any).fbq(action, title, data);
  }
}

class ContactForm extends React.Component<Props, State> {
  state = {
    state: FormState.Normal,
    "bot-field": ""
  };

  feedbackRef = React.createRef<HTMLDivElement>();

  handleChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  };

  handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const form: any = event.target;
    try {
      this.setState({ state: FormState.Submitting });
      const { state: ignoreState, ...formData } = this.state;
      await fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...formData
        })
      });
      this.setState({ state: FormState.Success }, () => {
        this.feedbackRef.current && this.feedbackRef.current.scrollIntoView();
        trackCustomEvent({
          category: "Contatti",
          action: "Invio Form",
          label: this.props.pageName
        });
        fbTrack("track", "Lead", { page: this.props.pageName });
      });
    } catch (error) {
      this.setState({ state: FormState.Error, error: error.message }, () => {
        this.feedbackRef.current && this.feedbackRef.current.scrollIntoView();
      });
    }
  };

  handleReset = () => {
    this.setState({ state: FormState.Normal });
  };

  render() {
    const { state } = this.state;
    if (state === FormState.Success) {
      return (
        <Feedback ref={this.feedbackRef}>
          <h3>
            <FormattedMessage
              id="contacts.form.thankYou"
              defaultMessage="Thank you!"
            />
          </h3>
          <p>
            <FormattedMessage
              id="contacts.form.thankYouDetails"
              defaultMessage="We'll get in touch soon."
            />
          </p>
        </Feedback>
      );
    }
    if (state === FormState.Error) {
      return (
        <Feedback ref={this.feedbackRef}>
          <h3>
            <FormattedMessage
              id="contacts.form.errorMessage"
              defaultMessage="Ops, an error occurred!"
            />
          </h3>
          <Button onClick={this.handleReset}>
            <FormattedMessage
              id="contacts.form.tryAgain"
              defaultMessage="Try again"
            />
          </Button>
        </Feedback>
      );
    }
    return (
      <FormContainer>
        <Form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <BotField>
            <label>
              Don’t fill this out if you're human:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </BotField>
          <FormField>
            <label htmlFor="name">
              <FormattedMessage id="contacts.form.name" defaultMessage="Name" />
            </label>
            <FormattedMessage
              id="contacts.form.namePlaceholder"
              defaultMessage="Peter Smith"
            >
              {txt => (
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder={txt as string}
                  required
                  onChange={this.handleChange}
                />
              )}
            </FormattedMessage>
            <span className="focus-border" />
          </FormField>
          <FormField>
            <label htmlFor="email">
              <FormattedMessage
                id="contacts.form.email"
                defaultMessage="Email"
              />
            </label>

            <FormattedMessage
              id="contacts.form.emailPlaceholder"
              defaultMessage="example@yourdomain.com"
            >
              {txt => (
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={txt as string}
                  required
                  onChange={this.handleChange}
                />
              )}
            </FormattedMessage>
            <span className="focus-border" />
          </FormField>
          <FormField>
            <label htmlFor="telefono">
              <FormattedMessage id="contacts.form.tel" defaultMessage="Email" />
            </label>

            <FormattedMessage
              id="contacts.form.telPlaceholder"
              defaultMessage="example@yourdomain.com"
            >
              {txt => (
                <input
                  type="tel"
                  id="tel"
                  name="tel"
                  placeholder={txt as string}
                  required
                  onChange={this.handleChange}
                />
              )}
            </FormattedMessage>
            <span className="focus-border" />
          </FormField>
          <FormField>
            <label htmlFor="message">
              <FormattedMessage
                id="contacts.form.message"
                defaultMessage="Message"
              />
            </label>
            <FormattedMessage
              id="contacts.form.messagePlaceholder"
              defaultMessage="Hi there..."
            >
              {txt => (
                <TextareaAutosize
                  id="message"
                  name="message"
                  placeholder={txt as string}
                  required
                  onChange={this.handleChange}
                />
              )}
            </FormattedMessage>
            <span className="focus-border" />
          </FormField>
          <SendButton>
            <FormattedMessage id="contacts.form.send" defaultMessage="Send" />
          </SendButton>
        </Form>
      </FormContainer>
    );
  }
}

const FormContainer = styled.div`
  margin: 0 auto;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 50px 30px;
  margin-top: 32px;
`;

const BotField = styled.div`
  display: none;
`;

const Feedback = styled.div`
  padding-top: 36px;
  padding-bottom: 50px;
  text-align: center;
  h3 {
    font-family: ${({ theme }) => theme.fonts.title};
    font-weight: 400;
    color: ${({ theme }) => theme.mainColor};
    font-size: 46px;
    padding: 0;
    margin: 0;
    font-weight: 700;
    line-height: 1.4em;
    max-width: 800px;
    padding-bottom: 40px;
    @media (max-width: 900px) {
      font-size: 40px;
    }
    @media (max-width: 600px) {
      font-size: 32px;
    }
    @media (max-width: 500px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 14px;
    color: #5c5c5c;
  }
`;

const Form = styled.form`
  flex-grow: 1;
`;

const FormField = styled.div`
  display: inline-block;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  &.half {
    width: calc(50% - 20px);
    @media (max-width: 950px) {
      width: calc(100% - 20px);
    }
    @media (max-width: 800px) {
      width: calc(50% - 20px);
    }
    @media (max-width: 600px) {
      width: calc(100% - 20px);
    }
  }
  label {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
    width: 100%;
    display: block;
  }
  input,
  textarea {
    border: none;
    position: relative;
    outline: none;
    border-bottom: 1px solid #949494;
    width: 100%;
    min-height: 40px;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.04em;
    display: block;
    resize: none;
    line-height: 1.4em;
    ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.textColorDark};
      transition: 0.4s;
    }
    &:focus {
      ~ .focus-border {
        width: 100%;
        transition: 0.4s;
      }
    }
    &::placeholder {
      color: #ccc;
    }
  }
`;

const Button = styled.button`
  color: ${({ theme }) => theme.textColorLight};
  background-color: ${({ theme }) => theme.mainColor};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.04em;
  overflow: hidden;
  display: block;
  position: relative;
  /* min-width: 200px; */
  height: 40px;
  transition: all 0.3s;
  text-transform: uppercase;
  cursor: pointer;
`;

const SendButton = styled(Button).attrs({ type: "submit" })`
  margin-bottom: 10px;
  width: 100%;
  border: 0;
  font-family: ${({ theme }) => theme.fonts.text};
  letter-spacing: 0.1em;
`;

export default ContactForm;
